<template>
  <div class="reviews_page">
    <div class="block_5">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <h1>
            {{ $t('messages.page.title') }}
          </h1>
          <h4>
            {{ $t('messages.page.subtitle') }}
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="text-right"
        >
          <b-button
            variant="primary"
            size="lg"
            class="mt-2 mb-2"
            @click="openedAddReviewModal = true;"
          >
            {{ $t('messages.page.btn') }}
          </b-button>
        </b-col>
      </b-row>

      <b-tabs
        align="center"
        pills
      >
        <b-tab :title="$t('messages.types.admin.buyer')">
          <b-row>
            <b-col
              v-for="review in getByBuyer"
              :key="review.id"
              cols="12"
              md="6"
            >
              <b-card>
                <h6 class="font-weight-bolder mb-25">
                  {{ review.title }}
                </h6>
                <b-card-text>
                  {{ review.created_at }}
                </b-card-text>
                <b-card-text>
                  {{ review.message }}
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="$t('messages.types.admin.seller')">
          <b-row>
            <b-col
              v-for="review in getBySeller"
              :key="review.id"
              cols="12"
              md="6"
            >
              <b-card>
                <h6 class="font-weight-bolder mb-25">
                  {{ review.title }}
                </h6>
                <b-card-text>
                  {{ review.created_at }}
                </b-card-text>
                <b-card-text>
                  {{ review.message }}
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
    <AddReviewModal
      :opened-add-review-modal.sync="openedAddReviewModal"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import GlobalMixin from '@mixins/GlobalMixin'
import store from '@/store'
import _ from 'lodash'
import AddReviewModal from '@/views/info/AddReviewModal'

export default {
  components: {
    AddReviewModal,
  },
  mixins: [GlobalMixin],
  metaInfo: {
    title: 'Відгуки | TeLeAds — біржа реклами в Телеграм',
    meta: [
      { vmid: 'description', name: 'description', content: 'Відгуки власників каналів та клієнтів ✔️ Залишайте зворотній зв\'язок на цій сторінці ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      openedAddReviewModal: false,
      messages: [],
      downImg: require('@/assets/images/pages/error.svg'),
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    refDescription() {
      return this.$i18n.t('account.text.referral.description').replace(':comm', '2.9')
    },
    getByBuyer() {
      return _.filter(this.messages, o => o.type === 'buyer')
    },
    getBySeller() {
      return _.filter(this.messages, o => o.type === 'seller')
    },
  },
  created() {
    this.fetchReviews()
  },
  methods: {
    fetchReviews() {
      this.$http.get('/api/promo/messages/', {
        params: {
          per_page: 99,
        },
      })
        .then(response => {
          this.messages = response.data.data
        })
    },
  },
}
</script>

<style lang="scss">
.reviews_page {
  .content-header {
    display: none;
  }

  .block_5 {
    h1 {
      font-size: 3em;
      display: block;
      margin: 0 0 1em 0;
      font-weight: bold;
    }

    h4 {
      font-size: 1.4em;
      display: block;
      margin: 0 0 1.4em 0;
      font-weight: bold;
    }

    @media (max-width: 700px) {
      h1 {
        font-size: 2em;
      }
    }
  }
}
</style>
