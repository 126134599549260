<template>
  <b-sidebar
    id="opened-add-review-modal"
    :visible="openedAddReviewModal"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-add-review-modal', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('messages.modal.title') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <!-- BODY -->

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-for="type"
            >
              <b-form-radio-group
                v-model="form.type"
                :options="types"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                :placeholder="$t('messages.modal.from')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="title"
            >
              <b-form-textarea
                id="title"
                v-model="form.message"
                rows="8"
                :placeholder="$t('messages.modal.message')"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              block
              @click="submit"
            >
              {{ $t('messages.modal.btn') }}
            </b-button>
          </b-col>
        </b-row>

      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BFormRadioGroup, BFormTextarea,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormRadioGroup,
    BFormTextarea,
  },
  model: {
    prop: 'openedAddReviewModal',
    event: 'update:opened-add-review-modal',
  },
  props: {
    openedAddReviewModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      types: [
        { text: this.$i18n.t('messages.types.buyer'), value: 'buyer', disabled: false },
        { text: this.$i18n.t('messages.types.seller'), value: 'seller', disabled: false },
      ],
      form: {
        type: 'buyer',
        title: null,
        message: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-add-review-modal', false)
    },
    submit() {
      this.$http.post('/api/promo/messages/', {
        type: this.form.type,
        title: this.form.title,
        message: this.form.message,
      })
        .then(() => {
          this.$store.dispatch('validation/clearErrors')
          this.$emit('update:opened-add-review-modal', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-add-review-modal {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
